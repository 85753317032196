import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Newsletter from '../components/Newsletter';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import AboutImages from '../components/AboutImages';
import {AiFillFacebook} from 'react-icons/ai';
import {GoCheck} from 'react-icons/go';
import Helmet from 'react-helmet';

function ThanksLanding () {
  const aboutImages = AboutImages ();
  return (
    <Layout>
      <SEO
        keywords={[`About`, 'Diego Valle', 'Diego Valle-Jones']}
        title="Privacy Policy"
        description=""
      />
      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
        <h1 className="text-center">
          Privacy Policy
        </h1>

        <section className="p-4 container">
          <p className="pb-8">Last revised on 2023-11-03</p>
          <p className="pb-4">
            www.diegovalle.net, blog.diegovalle.net Privacy Policy
          </p>
          <p className="pb-4">
            This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.diegovalle.net or blog.diegovalle.net (the “Site”).
          </p>
          <h2 className="pb-4">PERSONAL INFORMATION WE COLLECT</h2>
          <p className="pb-4">
            When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
          </p>
          <p className="pb-4">
            We collect Device Information using the following technologies:
          </p>
          <div className="whitespace-pre-line bg-gray-300 my-4 overflow-x-auto">
              - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.<br/>
              - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.<br/>
              - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.<br/>
              - Contact information including email address and name from the contact form.<br/>
          </div>
          <p className="pb-4">
            Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information, email address, and phone number.  We refer to this information as “Order Information.”
          </p>
          <p className="pb-4">
            Server log files
            You can use our websites without submitting personal data. Every time you access our website, user data is transmitted by your internet browser and stored in protocol files (server log files). This stored data includes e.g. name of the site called up, date and time of the request, amount of data transferred and the provider making the request. This data serves exclusively to ensure smooth operation of our website and to improve our offering. It is not possible to assign this data to a particular person.
          </p>
          <p className="pb-4">
            Collection and processing when using the contact form
            When you use the contact form we will only collect your personal data (name, email address, message text) in the scope provided by you. The data processing is for the purpose of making contact. By submitting your message you agree to the processing of your transmitted data. Processing will be carried out on the basis of art. 6 (1) lit. a GDPR with your consent.You can withdraw your consent at any time by contacting us without affecting the legality of the processing carried out with your consent up to the withdrawal. We will only use your email address to process your request. Finally your data will be deleted, unless you have agreed to further processing and use.
          </p>
          <p className="pb-4">
            Use of Google reCAPTCHA
            Our website uses the reCAPTCHA service by Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; “Google”). The request serves to distinguish whether the input was made by a human or automatic machine processing. The request includes the submission of the IP address and any other data required by Google for the reCAPTCHA service. For this purpose your input will be transmitted to Google and used further there. Google will, however, shorten your IP address beforehand within the Member States of the European Union or other signatories to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transferred to a Google server in the USA and shortened there. Google will use this information on behalf of the operator of this website in order to assess your use of this service. The IP address transmitted by your browser as part of reCAPTCHA is not associated with any other data held by Google. Your data may also be transmitted to the USA. Transmission of data to the USA is covered by an adequacy decision by the European Commission, the “Privacy Shield”. Google participates in “Privacy Shield” and has submitted to its requirements. By activating the request you agree to the processing of your data. The processing will be carried out on the basis of art. 6 (1) lit. a GDPR with your consent. You can withdraw your consent at any time without affecting the legality of the processing carried out with your consent up to the withdrawal.You can find more detailed information on Google reCAPTCHA and the associated data protection declaration at:
            {' '}
            <a href="https://policies.google.com/technologies/ads?hl=en">
              https://policies.google.com/technologies/ads?hl=en
            </a>
          </p>
          <h2 className="pb-4">Mailing List</h2>
          <p className="pb-4">
            www.diegovalle.net or blog.diegovalle.net maintains an email mailing list of those who freely subscribe via a third-party server, in this case Sendgrid. These emails are used by the blog owner only to email blog newsletters, occasional updates, and promotional content. You are free to unsubscribe at any time using the link at the bottom of your newsletter emails. Your email will never be passed on to third parties by www.diegovalle.net or blog.diegovalle.net.
          </p>
          <h2 className="pb-4">Email Verification</h2>
          <p className="pb-4">
            www.diegovalle.net or blog.diegovalle.net uses neverbounce to validate the email addresses you entered in the form to subscribe to newsletter. This is done to check that emails are valid and avoid sending spam.
          </p>
          <h2 className="pb-4">Comments</h2>
          <p className="pb-4">
            www.diegovalle.net or blog.diegovalle.net allows Disqus comments to be added beneath all blog posts on this site. The information collected here, which may include names, email addresses and/or website URL’s, will not be added to my mailing list, nor will it ever be passed on or shared with third parties.
          </p>
          <p className="pb-4">
            There is a comment check box which you must click only if you are happy to allow your data to be collected for the sole purpose of adding your comment beneath the blog post. If you are not happy for your data to be collected and stored, do not click the box, and your data and comment will not be added to www.diegovalle.net or blog.diegovalle.net.
          </p>
          <p className="pb-4">
            The owner of this blog reserves the right to edit or delete any comments submitted to this blog without notice due to, but not limited to:
          </p>
          <p className="pb-4">
            Comments deemed to be spam or questionable spam
            Comments including profanity
            Comments containing language or concepts that could be deemed offensive
            Comments that attack a person individually
            Comments which contain links that are questionable, such as broken links or invalid links.
            The owner of this blog reserves the right to change the focus of this blog, shut it down, or sell it at her own discretion.
          </p>
          <h2 className="pb-4">Review of Anonymous Data from Google Analytics</h2>
          <p className="pb-4">
            I am committed to ensuring that my website contains content that is valuable and useful to my readers. To help me with this I have implemented the Google Analytics demographics and interest reporting features on my website. These tools allow me to review anonymous data regarding the gender, age and interests of website visitors and adapt my website content to better reflect their needs. Although this information is collected through your Google ad settings, it is not provided to me in a personally-identifiable format. This means I cannot identify what information is about you, and I will never attempt to do so.
          </p>
          <p className="pb-4">
            You can opt-out of Google Analytics for Display Advertising and customise Google Display Network ads using the Google Ad settings or through the Google Analytics opt-out browser add-on.
          </p>
          <p className="pb-4">
            When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
          </p>
          <h2 className="pb-4">HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>
          <p className="pb-4">
            We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).  Additionally, we use this Order Information to:
            Communicate with you;
            Screen our orders for potential risk or fraud; and
            When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
          </p>
          <p className="pb-4">
            We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
          </p>
          <h2 className="pb-4">SHARING YOUR PERSONAL INFORMATION</h2>
          <p className="pb-4">
            We share your Personal Information with third parties to help us use your Personal Information, as described above.  For example, we use Shopify to power our online store--you can read more about how Shopify uses your Personal Information here:
            {' '}
            <a href="https://www.shopify.com/legal/privacy">
              https://www.shopify.com/legal/privacy
            </a>
            .  We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:
            {' '}
            <a href="https://www.google.com/intl/en/policies/privacy/">
              https://www.google.com/intl/en/policies/privacy/
            </a>
            .  You can also opt-out of Google Analytics here:
            {' '}
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
            .
          </p>
          <p className="pb-4">
            Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
          </p>
          <p className="pb-4">
            DO NOT TRACK
            Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.
          </p>
          <p className="pb-4">
            YOUR RIGHTS
            If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
          </p>
          <p className="pb-4">
            Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
          </p>
          <p className="pb-4">
            DATA RETENTION
            When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
          </p>
          <p className="pb-4">
            CHANGES
            We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
          </p>
          <p className="pb-4">
            CONTACT US
            For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail
          </p>
        </section>

      </main>
    </Layout>
  );
}

export default ThanksLanding;



export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "about", "header", "newsletter"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;